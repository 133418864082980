<template>
  <main class="content container">
    <p class="text-center">
      <button v-if="status && orderId" class="btn btn-primary" :class="{ 'disabled': disabledBtn, '': !disabledBtn }" @click="getCheckout">
        Einfach und direkt bezahlen
      </button>
    </p>
  </main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
import axios from 'axios'
export default {
  setup () {
    useMeta({
      title: 'Mollie'
    })
    const disabledBtn = ref(false)
    const status = ref(true)
    const store = useStore()
    // computed
    const orderId = computed(() => {
      return store.getters['checkout/orderId']
    })
    // actions
    async function getCheckout () {
      disabledBtn.value = true
      const apiUrl = process.env.VUE_APP_API_URL
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      await axios.post(apiUrl + 'mollie/checkout', { re: apiKey, order_id: this.orderId, domain: window.location.origin }).then(resp => {
        if (resp?.data?.status) {
          status.value = false
          disabledBtn.value = false
          window.location = resp.data.payment_url
        } else {
          disabledBtn.value = true
          status.value = true
        }
      })
    }
    return {
      status,
      disabledBtn,
      orderId,
      getCheckout
    }
  }
}
</script>
